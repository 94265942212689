// Light Mode
export const defaultColor = '#f4f3f2';
export const navBarColor = '#f4f3f2';
export const searchBarColor = '#ffffff';
// export const thumbNailColor = '#f4f3f2';
export const componentColor = '#f4f3f2';
export const textMedLight = '#555555';
export const textMedHeavy = '#333333';
export const outlineColor = '#333333';
export const ingredientBackgroundColor = 'rgba(55, 55, 55, 0.9)';
export const windowColor = '#f4f3f2';
export const mainAppColor = '#f4f3f2';
export const defaultPageColor = '#f4f3f2';
export const defaultPageNeonColor = '0 0 8px rgba(0, 0, 0, 0.4)';
export const pageColor = '#f4f3f2';
export const pageSectionColor = '#f4f3f2';
export const sectionItemColor = '#f4f3f2';
export const randomTempColor = '#669dd4';
export const leftColumnColor = '#f4f3f2';
export const buttonColorArrow = 'rgba(50, 50, 50, 0.6)';
export const buttonBackgroundColor = 'rgba(50, 50, 50, 0)';
export const selectionColor = 'rgba(50, 50, 50, 0.35)';
export const buttonColor = '#2557a7';
export const buttonPlusColor = '#2557a7';
export const buttonCheckColor = '#228b22';
export const buttonMinusColor = '#CE2029';
export const popupColor = '#ffcc00';
export const glowColorThin = '0 0 8px rgba(0, 0, 0, 0.2)';
export const glowColorMedium = '0 0 8px rgba(0, 0, 0, 0.3)';
export const glowColorThick = '0 0 8px rgba(0, 0, 0, 0.4)';
export const ingredientPageGlow = '0 0 8px rgba(0, 0, 0, 0.4)';
export const subTextColor = '#555';
